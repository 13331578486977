.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 16rem;
    width: 100%;
    padding: 1.1rem 0;
    height: 4.1rem;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.9rem;
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--colorMain);
    border-radius: .4rem;
    border: none;
    outline: none;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
        opacity: .8;
    }

    &:disabled {
        background: var(--colorGrey);
    }

    span {
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.9rem;
        letter-spacing: 0.01em;
        color: var(--colorMain);
        margin-left: 1.5rem;
    }

    &_plus {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.75rem;
    }

    &__green {
        background-color: var(--colorGreen);
    }

    &__grey {
        background-color: var(--colorGrey);

        &_ghost {
            background: transparent;
            border: .1rem solid var(--colorGrey);
            color: var(--colorGrey);
        }
    }
}