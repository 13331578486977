.c-light {

    &__wrap {
        margin-bottom: 1.5rem;
        padding: 0 .5rem 0 .5rem;
    }

    &__heading {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.2rem;
        letter-spacing: 0.01em;
        color: var(--colorBlack);
        margin-bottom: 1.7rem;
    }

    &__input {
        position: relative;
    }

    &__value-thumb {
        position: absolute;
        top: 50%;
        left: .2rem;
        transform: translate(0, .1rem);
        height: .2rem;
        background-color: var(--colorDarkGrey);
        z-index: 9;
    }

    &__numb {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.8rem;
        letter-spacing: 0.01em;
        color: var(--colorBlack);

        &_left {
            display: block;
            position: absolute;
            top: -1.2rem;
            left: 0;
        }

        &_right {
            display: block;
            position: absolute;
            top: -1.2rem;
            right: 0;
        }
    }
}