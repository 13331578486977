.scenes {
    &__toggle {
        padding: 1rem 2.5rem !important;
        border-radius: 4px !important;
        font-weight: 500 !important;
        font-size: 1.4rem !important;
        line-height: 2.1rem !important;
        text-align: center !important;
        letter-spacing: 0.01em !important;
        color: var(--colorDarkGrey) !important;

        &.Mui-selected {
            background: #D9E3F2 !important;
        }
    }

    &__item {
        border-bottom: .1rem solid #E2EAF3;
        padding: 0 .8rem 0 0 !important;
        &:first-child {
            border-top: .1rem solid #E2EAF3;
        }

        &_disabled {
            user-select: none;
            filter: grayscale(100%);
            background: rgb(60%, 59%, 59%, .2);
            opacity: .5 !important;
        }

        p {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2.1rem;
            letter-spacing: 0.01em;
            color: var(--colorBlack);
        }

        .MuiTreeItem-content {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: .5rem;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: 0;
                height: 0;
                border-width: 0 4px 4px 4px;
                border-color: transparent transparent #C2CFE0 transparent;
                border-style: solid;
            }
        }

        .MuiCollapse-wrapperInner .MuiTreeItem-iconContainer,
        .MuiCollapse-wrapperInner .MuiTreeItem-content:before {
            display: none !important;
        }
        
        .MuiCollapse-wrapperInner .MuiTreeItem-content {
            padding-left: .4rem;
        }

        &.Mui-expanded {
            .MuiTreeItem-content {
                position: relative;
    
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: .5rem;
                    top: 50%;
                    transform: translateY(-50%) rotate(0deg);
                    width: 0;
                    height: 0;
                    border-width: 0 4px 4px 4px;
                    border-color: transparent transparent #C2CFE0 transparent;
                    border-style: solid;
                }
            }
        }

        .Component-group-58,
        .MuiCollapse-container {
            border: none;
            margin-left: 0;
            padding-left: 0;
        }

        .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
        .MuiTreeItem-label {
            background: none !important;
        }
    }
}

.scenes-details {
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    &__icon {
        display: block;
       width: 1.5rem;
        height: 1.5rem;
        margin-right: 1.24rem;
    }

    &__button {
        position: relative;
        display: block;
        width: 4.5rem;
        height: 4.5rem;
        cursor: pointer;
        user-select: none;
        margin-right: 1.24rem;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(233deg);
        }

        &:hover:not([disabled]) path,
        &:hover:not([disabled]) circle {
            stroke: var(--colorGreen);
        }
    }

    &__input {
        width: 100%;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.9rem;
        letter-spacing: 0.01em;
        color: var(--colorBlack);
        padding: .7rem .2rem .7rem 2.4rem;
        border: .1rem solid var(--colorBorderGrey);
        border-radius: .4rem;
    }
}