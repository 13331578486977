.modal {
    &__inner {
        position: relative;
        height: 100%;
    }

    &__content {
        position: absolute;
        max-width: 77.8rem;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 5rem 6rem 4rem 6rem;
        border-radius: 4px;
        background: var(--colorMain);
    }

    &__heading {
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 3.6rem;
        letter-spacing: 0.01em;
        color: var(--colorBlack);
        margin-bottom: 3.5rem;
    }

    &__subheading {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.2rem;
        letter-spacing: 0.01em;
        color: var(--colorBlack);
        margin-bottom: .7rem;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__side {
        width: 50%;

        &:first-child {
            width: 45%;
        }
    }

    &__imagecontainer {
        position: relative;
        width: 100%;
        height: 26.1rem;
        border: .1rem dashed #C2CFE0;
        background: #FCFDFF;
        border-radius: .2rem;

        &_empty {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        // border: .1rem dashed #3f5d85;
        cursor: pointer;
    }

    &__title {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2.1rem;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--colorBlack);
        margin-bottom: .5rem;

        &::before {
            content: '';
            display: block;
            background: url('../../../images/icons/image.svg') no-repeat;
            width: 4.3rem;
            height: 4.3rem;
            background-size: contain;
            margin: 0 auto;
            margin-bottom: 1.4rem;
        }

        span {
            color: var(--colorGreen);
        }
    }

    &__access {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--colorGrey);
    }

    &__submit {
        display: block;
        margin: 0 auto;
        margin-top: 5rem;
    }

    .MuiAutocomplete-tag {
        // display: flex;
        // align-items: center;
        // font-weight: 500;
        // font-size: 1.4rem;
        line-height: 2.1rem;
        // text-align: center;
        // letter-spacing: 0.01em;
        // color: var(--colorBlack);
        background: transparent;
        // border: none;
        // border-radius: 2px;

        &::before {
            content: '#';
            // display: block;
            // font-weight: 500;
            font-size: 1.7rem;
            // line-height: 2.1rem;
            margin-right: -1rem;
        }

        svg {
            opacity: 0;
            width: 1.4rem;
            margin-left: -1rem;
            margin-right: -.1rem;
        }

        &:hover svg{
            opacity: 1;
        }
    }

    .MuiOutlinedInput-root {
        background: #FFFFFF;
        border-color: #E2EAF3;
        box-sizing: border-box;
        outline: none !important;

        input {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2.1rem;
            letter-spacing: 0.01em;
            color: var(--colorBlack);
            outline: none !important;
            padding: 15.5px 14px;
            caret-color: var(--colorGreen);

            &::placeholder {
                color: #C2CFE0;
            }
        }

    }

    .MuiInputLabel-formControl {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.1rem;
        letter-spacing: 0.01em;
        z-index: 1;
        transform: translate(16px, 70%) scale(1);
        pointer-events: none;
        color: #C2CFE0;
    }

    .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
        fill: #C2CFE0;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #E2EAF3;
    }

    .MuiFormHelperText-root.Mui-error {
        font-size: 1.2rem;
        margin: 0;
    }

    &__controls {
        width: 100%;
        display: flex;
        margin-top: 5rem;
        justify-content: center;
    }

    &__button {
        margin: 0 1rem;
    }
}

.popup {
    .card__item {
        width: calc(33.33%) !important;
        padding: 0 calc(var(--gap) * 3) !important;
    }
}
