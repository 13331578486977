@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700,800');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,800');
@import url('https://fonts.googleapis.com/css?family=Prompt:300,400,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800');

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

html {
  font-size: 62.5%;
}

[hidden] {
  display: none !important;
}

body {
  font-size: 1.6rem;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

body, a, p, h1, h2, h3, span {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 1.6rem;
}

input {
  font-size: 1.6rem;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.2rem;

  &__back {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.6rem;
    letter-spacing: 0.01em;
    color: var(--colorBlack);

    &_length {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.6rem;
      letter-spacing: 0.01em;
      color: var(--colorGrey);
    }

    &:before {
      content: '';
      display: block;
      width: 2.8rem;
      height: 2.8rem;
      background: url('../../../images/icons/arrowleft.svg') no-repeat;
      background-size: contain;
      margin-right: .6rem;
    }
  }
}

.app {
  &__input {
    width: 100%;
    padding: .7rem 1rem !important;

  }
}

.slider {

  &__light {
      -webkit-appearance: none;
      width: 100%;
      height: .2rem;
      background: #C2CFE0;
      outline: none;
      border-radius: .4rem;

      &::-webkit-slider-thumb {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: var(--colorDarkGrey);
        cursor: ew-resize;
        z-index: 10;
      }
  }

  &__range {
      -webkit-appearance: none;
      width: 100%;
      height: .2rem;
      background: #C2CFE0;
      outline: none;
      border-radius: .4rem;

      &_vertical {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(45%, -50%) rotate(90deg);
        max-height: 3rem;
        max-width: 15rem;

        &::-webkit-slider-thumb {
          cursor: ns-resize !important;
        }
      }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 2.1rem;
      height: 1rem;
      background: url('../../../images/icons/range.svg') no-repeat;
      cursor: ew-resize;

    }
  }
}

.MuiAutocomplete-listbox {
  border: .1rem solid #E2EAF3 !important;
  padding: 0 !important;
  box-sizing: border-box;
  border-radius: .2rem !important;
}

.MuiAutocomplete-option {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  letter-spacing: 0.01em;
  color: var(--colorBlack);
  padding: 1.4rem;
  &:hover {
    background: #D6E0EE !important;
  }
}

.profile {

  padding: 1.8rem !important;
  margin: 1rem 0 2rem 0;
  transition: .3s all;

  &:hover .profile__settings {
    opacity: 1;
  }

  &_shifted {
    padding: 1rem .5rem !important;
  }

  &__icon {
    display: block !important;
    padding: .5rem !important;
    margin: 1.4rem 0 2rem 0 !important;
    &_source {
      width: 4.6rem !important;
      height: 4.6rem !important;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__settings {
    opacity: 0;
    padding: 0 !important;
    background-color: none;
  }

  .MuiCardHeader-title {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: var(--colorBlack);
  }

  .MuiCardHeader-subheader {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.6rem;
    letter-spacing: 0.01em;
    color: #90A0B7;
  }
}

.page {

  &__notice {
    font-size: 1.6rem;
  }
}

.light__canvas {
  min-height: inherit;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}
