.list {
  &__controls {
    position: absolute;
    top: .9rem;
    right: 2.6rem;
    display: flex;
    background: var(--colorMain);
    width: fit-content;
    border-radius: 15px;
    padding: .5rem;
    z-index: 99;
    align-items: 'center';
  }

  &__control {
    width: 1.6rem;
    height: 1.6rem;

    &_mr {
      margin-right: .8rem;
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    &:hover path {
      stroke: var(--colorGreen);
    }

    &:last-child:hover path{
      fill: var(--colorGreen);
      stroke: transparent;
    }
  }
}
