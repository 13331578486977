.card {

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        // margin: 0 calc(var(--gap) * -4);
        margin-top: 5rem;
    }

    &__wrapper {
        width: 100%;
        margin-top: 1.4rem;
    }

    &__item {
        position: relative;
        display: block;
        width: calc(100%/4);
        
        // padding: 0 calc(var(--gap) * 4);
        // margin: 0 0 calc(var(--gap) * 12) 0;
        margin-bottom: 3rem;
        cursor: pointer;

        &_selected {
            border-radius: .2rem;
            // border: .1rem solid var(--colorGreen);
            -webkit-box-shadow: 0px 0px 0px 1.5px var(--colorGreen), 0px 0px 0px 16px transparent, 1px -14px 6px 0px rgba(0,0,0,0); 
            box-shadow: 0px 0px 0px 1.5px var(--colorGreen), 0px 0px 0px 16px transparent, 1px -14px 6px 0px rgba(0,0,0,0);
        }
        div[data-loader=obj] {
            width: 100% !important;
        }
        canvas {
            width: 100% !important;
        }

    }
}