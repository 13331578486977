.checkbox {

    &__wrap {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__subject {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        font-size: 1.3rem;
        line-height: 1.9rem;
        cursor: pointer;
        user-select: none;
    }
}

.checkbox__subject:checked ~ .checkbox__label:before {
    background: #ffffff;
    border-radius: .2rem;
}

.checkbox__subject ~ .checkbox__label:before {
    content: '';
    margin-right: 1rem;
    position: relative;
    top: -.3rem;
    display: inline-block;
    vertical-align: text-top;
    width: 2.6rem;
    height: 2.6rem;
    background: var(--colorMain);
    border: 0.1rem solid var(--colorBorderGrey);
    border-radius: .2rem;
}

.checkbox__subject:checked ~ .checkbox__label:after {
    content: '';
    position: absolute;
    left: .7rem;
    top: 1rem;
    background: none;
    width: .2rem;
    height: .2rem;
    box-shadow: 2px 0 0 var(--colorGreen), 4px 0 0 var(--colorGreen), 4px -2px 0 var(--colorGreen), 4px -4px 0 var(--colorGreen), 4px -6px 0 var(--colorGreen), 4px -8px 0 var(--colorGreen);
    transform: rotate(45deg);
}
