.interior {
    &__item {
        position: relative;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.03));
    }

    &__image {
        display: block;
        width: 100%;
        // height: calc(100% * .75) !important;
        height: 19.1rem;
        object-fit: contain;
        position: static !important;
        transform: none !important;
        top: auto !important;
        left: auto !important;
        background-color: var(--colorMain);
    }

    &__info {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 0;
        background: var(--colorMain);
    }

    &__heading {
        // width: calc(100% - 8rem);
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.2rem;
        letter-spacing: 0.01em;
        color: var(--colorBlack);
    }

    &__sizes {
        display: block;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.2rem;
        letter-spacing: 0.01em;
        color: var(--colorGrey);
    }
}


.file__error {
    font-size: 1.2rem;
    color: var(--colorRed);
    margin-top: 1rem;
}