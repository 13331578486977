.sort {
    &__text {
        display: block;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.8rem;
        letter-spacing: 0.01em;
        color: var(--colorDarkGrey);
    }

    &__label {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        letter-spacing: 0.01em;
        color: var(--colorGreen);
    }
}