:root {
    --colorMain: #ffffff;
    --colorDarkWhite: #ECEEF3;
    --colorLightGrey: #E9EBEF;
    --colorBorderGrey: #C2CFE0;
    --colorBody: #F5F6F8;
    --colorGrey: #9CACC2;
    --colorBorder: #EBEFF2;
    --colorDarkGrey: #334D6E;
    --colorBlack: #192A3E;
    --colorGreen: #00C98F;
    --colorHoverGreen: #05DB9D;
    --colorRed: #EA2B4D;
    --colorFontBlack: #3D4252;
    --colorFontWhite: #F1F1F1;
    --shadowDark: 6px 0px 18px rgba(0, 0, 0, 0.06);
    --gap: .3rem;
}

@custom-media --tb-screen (width >= 537px);
@custom-media --md-screen (width >= 1023px);
@custom-media --lg-screen (width >= 1200px);
@custom-media --xlg-screen (width >= 1500px);
@custom-media --xxlg-screen (width >= 1921px);

@custom-media --mobile-screen (width <= 537px);
@custom-media --laptop-screen (width <= 768px);
@custom-media --desctop-screen (width <= 1200px);

@custom-selector :--heading h1, h2, h3, h4;

:--heading {
  margin: 0;
  padding: 0;
}