.filters {
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;

    .MuiList-root {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .MuiListItem-button {
        width: fit-content;
        
        span {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2.1rem;
            text-align: center;
            letter-spacing: 0.01em;
            color: #334D6E;
        }
    }

    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
        background: #D9E3F2;
        border-radius: 4px;
    }

    .MuiListItem-secondaryAction {
        padding-right: 1.6rem;
    }
}